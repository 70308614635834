import * as React from "react";

export const HabitCalendarLogoDargBg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.85 50.39">
    <defs>
      <style>{".cls-1{fill:#ffdc0b;}.cls-2{fill:#fff;}"}</style>
    </defs>
    <title>habit-calendar-logo-dark-bg_2</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M58,30.5Q56.08,29.35,54.89,26L54,23.49H44.23l-3,8H39.29l10.88-29h1.66L60.09,26c.93,2.73,2.21,4.19,3.83,4.4v1.1a6,6,0,0,1-1.77.22A7.77,7.77,0,0,1,58,30.5ZM45,21.51h8.33L49.29,10Z"
        />
        <path
          class="cls-1"
          d="M66.36,8.19a8.16,8.16,0,0,0-.52-3.32,2.06,2.06,0,0,0-2.07-1.08h-.36V2.47h10A17.53,17.53,0,0,1,81.12,4q3.12,1.55,3.12,6.16A6.18,6.18,0,0,1,82.89,14a7.26,7.26,0,0,1-3.78,2.53q6.23.75,6.23,7,0,4.57-3.06,6.31a15.76,15.76,0,0,1-7.81,1.73H66.36Zm7.55,7.43a5.21,5.21,0,0,0,3.78-1.34A5.83,5.83,0,0,0,79.07,10c0-2-.54-3.48-1.64-4.38a6.23,6.23,0,0,0-4.07-1.34,5.25,5.25,0,0,0-1.55.18l-.66.13V15.62Zm0,14.11a6.36,6.36,0,0,0,4.67-1.58,6,6,0,0,0,1.6-4.48q0-6.08-6.27-6.07H71.15V29.29a2.7,2.7,0,0,0,.73.22,7,7,0,0,0,1.85.22Z"
        />
        <path
          class="cls-1"
          d="M90.8,31a2.87,2.87,0,0,1-1-1.72,16.1,16.1,0,0,1-.29-3.49V2.47H94.3V25.78a8,8,0,0,0,.52,3.32,2.06,2.06,0,0,0,2.06,1.07h.37v1.32H92.83A3.47,3.47,0,0,1,90.8,31Z"
        />
        <path
          class="cls-1"
          d="M106.36,4.45h-2.62a4,4,0,0,0-3.15,1.23A6.06,6.06,0,0,0,99.5,9.73H98.39V6c0-2.34,1-3.52,3.14-3.52h17.32v2h-7.7v27h-4.79Z"
        />
        <path
          class="cls-2"
          d="M41.76,49.58a4.87,4.87,0,0,1-1.65-2.2,8.26,8.26,0,0,1-.57-3.15,8.58,8.58,0,0,1,.62-3.3,5.62,5.62,0,0,1,1.73-2.35,3.84,3.84,0,0,1,2.45-.86,5,5,0,0,1,2.13.4,3.2,3.2,0,0,1,1.31,1.06,2.28,2.28,0,0,1,.32,1c0,.41.06,1,.06,1.72h-.44a5.14,5.14,0,0,0-1-2.53,2.43,2.43,0,0,0-1.94-.87,2.68,2.68,0,0,0-2.34,1.3,8.4,8.4,0,0,0-.86,4.4,8.29,8.29,0,0,0,.79,3.82,2.55,2.55,0,0,0,2.41,1.52,2.94,2.94,0,0,0,1.87-.67A4.58,4.58,0,0,0,48,47l.5.49a5.25,5.25,0,0,1-1.68,2.22,4.66,4.66,0,0,1-5.08-.11Z"
        />
        <path
          class="cls-2"
          d="M56.35,49.62a3.43,3.43,0,0,1-1.21-1.88l-.33-1H50.94L49.75,50H49l4.29-12h.65l3.26,9.66c.37,1.13.87,1.73,1.51,1.81V50a2.2,2.2,0,0,1-.7.09A3,3,0,0,1,56.35,49.62Zm-5.11-3.7h3.29l-1.6-4.73Z"
        />
        <path
          class="cls-2"
          d="M59.66,40.43a3.63,3.63,0,0,0-.2-1.37.82.82,0,0,0-.82-.44H58.5v-.54h1.74a1.23,1.23,0,0,1,.8.22,1.19,1.19,0,0,1,.4.71,7.26,7.26,0,0,1,.11,1.42v8.78h5V50H59.66Z"
        />
        <path
          class="cls-2"
          d="M67.7,40.43a3.63,3.63,0,0,0-.2-1.37.82.82,0,0,0-.81-.44h-.15v-.54H73c.83,0,1.24.48,1.24,1.45v1.53h-.44a2.43,2.43,0,0,0-.46-1.64,1.69,1.69,0,0,0-1.36-.53H69.59v4.53h3.78v.81H69.59v5h2.49a2.07,2.07,0,0,0,1.38-.46,3.46,3.46,0,0,0,.92-1.6l.39.18-.42,1.43a2.87,2.87,0,0,1-.45,1,1,1,0,0,1-.82.31H67.7Z"
        />
        <path
          class="cls-2"
          d="M77.2,41.06v9h-.73V40.25a2.09,2.09,0,0,0-.3-1.2,1.15,1.15,0,0,0-1-.43h-.07v-.54h1a3,3,0,0,1,1.33.26,2.17,2.17,0,0,1,.86.82l5,7.25V38.08H84v12h-.67Z"
        />
        <path
          class="cls-2"
          d="M86.49,40.43a3.63,3.63,0,0,0-.2-1.37.82.82,0,0,0-.82-.44h-.14v-.54h4a6,6,0,0,1,4.47,1.55A5.88,5.88,0,0,1,95.29,44a7.34,7.34,0,0,1-.65,3.16,4.84,4.84,0,0,1-1.92,2.13,5.93,5.93,0,0,1-3.06.76H86.49Zm3.13,8.87a2.85,2.85,0,0,0,1.81-.63,4.08,4.08,0,0,0,1.23-1.83,7.93,7.93,0,0,0,.45-2.79,7.75,7.75,0,0,0-.45-2.76,4.22,4.22,0,0,0-1.24-1.84,2.87,2.87,0,0,0-1.83-.65h-.12a3.81,3.81,0,0,0-.77.07l-.32.06V49.18l.32.07a5.3,5.3,0,0,0,.77,0Z"
        />
        <path
          class="cls-2"
          d="M102.94,49.62a3.43,3.43,0,0,1-1.21-1.88l-.34-1H97.53L96.33,50h-.75l4.29-12h.65l3.26,9.66c.37,1.13.87,1.73,1.51,1.81V50a2.2,2.2,0,0,1-.7.09A3,3,0,0,1,102.94,49.62Zm-5.11-3.7h3.29l-1.6-4.73Z"
        />
        <path
          class="cls-2"
          d="M111.56,49.62a6.83,6.83,0,0,1-1.37-2l-1.45-3h-.59V50h-1.9v-9.6a3.63,3.63,0,0,0-.2-1.37.82.82,0,0,0-.82-.44h-.14v-.54h4a5.39,5.39,0,0,1,2.72.69A2.76,2.76,0,0,1,113,41.41a3,3,0,0,1-2.36,3l1.53,3.17c.57,1.2,1.17,1.86,1.8,2V50a1.45,1.45,0,0,1-.42.12,2.91,2.91,0,0,1-.57.06A2.12,2.12,0,0,1,111.56,49.62ZM109,43.83a1.87,1.87,0,0,0,1.39-.6,2.73,2.73,0,0,0,.57-1.91,2.71,2.71,0,0,0-.57-1.9,1.76,1.76,0,0,0-1.32-.62,2.61,2.61,0,0,0-.65.07l-.3.06v4.9Z"
        />
        <path class="cls-1" d="M17.5,0H0V50H10V7.5A7.5,7.5,0,0,1,17.5,0Z" />
        <path class="cls-1" d="M25,0V42.5A7.5,7.5,0,0,1,17.5,50H35V0Z" />
        <path
          class="cls-1"
          d="M20.91,15.93a9.81,9.81,0,0,0-3.21,2.51,20.53,20.53,0,0,0-3.47,6,46.39,46.39,0,0,0-1.72,5.87c-.22.93-.42,1.82-.71,2.66A4.69,4.69,0,0,1,10,35.5a4.19,4.19,0,0,0,4.08.06,9.79,9.79,0,0,0,3.21-2.5A20.69,20.69,0,0,0,20.77,27a46.5,46.5,0,0,0,1.73-5.87c.22-.92.42-1.81.71-2.65A4.68,4.68,0,0,1,25,16,4.21,4.21,0,0,0,20.91,15.93Z"
        />
      </g>
    </g>
  </svg>
);
export const HabitCalendarLogoLightBg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.85 50.39">
    <defs>
      <style>{".cls-1{fill:#ffdc0b;}"}</style>
    </defs>
    <title>habit-calendar-logo-lightbg</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path d="M41.76,49.58a4.87,4.87,0,0,1-1.65-2.2,8.26,8.26,0,0,1-.57-3.15,8.58,8.58,0,0,1,.62-3.3,5.62,5.62,0,0,1,1.73-2.35,3.84,3.84,0,0,1,2.45-.86,5,5,0,0,1,2.13.4,3.2,3.2,0,0,1,1.31,1.06,2.28,2.28,0,0,1,.32,1c0,.41.06,1,.06,1.72h-.44a5.14,5.14,0,0,0-1-2.53,2.43,2.43,0,0,0-1.94-.87,2.68,2.68,0,0,0-2.34,1.3,8.4,8.4,0,0,0-.86,4.4,8.29,8.29,0,0,0,.79,3.82,2.55,2.55,0,0,0,2.41,1.52,2.94,2.94,0,0,0,1.87-.67A4.58,4.58,0,0,0,48,47l.5.49a5.25,5.25,0,0,1-1.68,2.22,4.66,4.66,0,0,1-5.08-.11Z" />
        <path d="M56.35,49.62a3.43,3.43,0,0,1-1.21-1.88l-.33-1H50.94L49.75,50H49l4.29-12h.65l3.26,9.66c.37,1.13.87,1.73,1.51,1.81V50a2.2,2.2,0,0,1-.7.09A3,3,0,0,1,56.35,49.62Zm-5.11-3.7h3.29l-1.6-4.73Z" />
        <path d="M59.66,40.43a3.63,3.63,0,0,0-.2-1.37.82.82,0,0,0-.82-.44H58.5v-.54h1.74a1.23,1.23,0,0,1,.8.22,1.19,1.19,0,0,1,.4.71,7.26,7.26,0,0,1,.11,1.42v8.78h5V50H59.66Z" />
        <path d="M67.7,40.43a3.63,3.63,0,0,0-.2-1.37.82.82,0,0,0-.81-.44h-.15v-.54H73c.83,0,1.24.48,1.24,1.45v1.53h-.44a2.43,2.43,0,0,0-.46-1.64,1.69,1.69,0,0,0-1.36-.53H69.59v4.53h3.78v.81H69.59v5h2.49a2.07,2.07,0,0,0,1.38-.46,3.46,3.46,0,0,0,.92-1.6l.39.18-.42,1.43a2.87,2.87,0,0,1-.45,1,1,1,0,0,1-.82.31H67.7Z" />
        <path d="M77.2,41.06v9h-.73V40.25a2.09,2.09,0,0,0-.3-1.2,1.15,1.15,0,0,0-1-.43h-.07v-.54h1a3,3,0,0,1,1.33.26,2.17,2.17,0,0,1,.86.82l5,7.25V38.08H84v12h-.67Z" />
        <path d="M86.49,40.43a3.63,3.63,0,0,0-.2-1.37.82.82,0,0,0-.82-.44h-.14v-.54h4a6,6,0,0,1,4.47,1.55A5.88,5.88,0,0,1,95.29,44a7.34,7.34,0,0,1-.65,3.16,4.84,4.84,0,0,1-1.92,2.13,5.93,5.93,0,0,1-3.06.76H86.49Zm3.13,8.87a2.85,2.85,0,0,0,1.81-.63,4.08,4.08,0,0,0,1.23-1.83,7.93,7.93,0,0,0,.45-2.79,7.75,7.75,0,0,0-.45-2.76,4.22,4.22,0,0,0-1.24-1.84,2.87,2.87,0,0,0-1.83-.65h-.12a3.81,3.81,0,0,0-.77.07l-.32.06V49.18l.32.07a5.3,5.3,0,0,0,.77,0Z" />
        <path d="M102.94,49.62a3.43,3.43,0,0,1-1.21-1.88l-.34-1H97.53L96.33,50h-.75l4.29-12h.65l3.26,9.66c.37,1.13.87,1.73,1.51,1.81V50a2.2,2.2,0,0,1-.7.09A3,3,0,0,1,102.94,49.62Zm-5.11-3.7h3.29l-1.6-4.73Z" />
        <path d="M111.56,49.62a6.83,6.83,0,0,1-1.37-2l-1.45-3h-.59V50h-1.9v-9.6a3.63,3.63,0,0,0-.2-1.37.82.82,0,0,0-.82-.44h-.14v-.54h4a5.39,5.39,0,0,1,2.72.69A2.76,2.76,0,0,1,113,41.41a3,3,0,0,1-2.36,3l1.53,3.17c.57,1.2,1.17,1.86,1.8,2V50a1.45,1.45,0,0,1-.42.12,2.91,2.91,0,0,1-.57.06A2.12,2.12,0,0,1,111.56,49.62ZM109,43.83a1.87,1.87,0,0,0,1.39-.6,2.73,2.73,0,0,0,.57-1.91,2.71,2.71,0,0,0-.57-1.9,1.76,1.76,0,0,0-1.32-.62,2.61,2.61,0,0,0-.65.07l-.3.06v4.9Z" />
        <path
          class="cls-1"
          d="M58,30.5Q56.08,29.35,54.89,26L54,23.49H44.23l-3,8H39.29l10.88-29h1.66L60.09,26c.93,2.73,2.21,4.19,3.83,4.4v1.1a6,6,0,0,1-1.77.22A7.77,7.77,0,0,1,58,30.5ZM45,21.51h8.33L49.29,10Z"
        />
        <path
          class="cls-1"
          d="M66.36,8.19a8.16,8.16,0,0,0-.52-3.32,2.06,2.06,0,0,0-2.07-1.08h-.36V2.47h10A17.53,17.53,0,0,1,81.12,4q3.12,1.55,3.12,6.16A6.18,6.18,0,0,1,82.89,14a7.26,7.26,0,0,1-3.78,2.53q6.23.75,6.23,7,0,4.57-3.06,6.31a15.76,15.76,0,0,1-7.81,1.73H66.36Zm7.55,7.43a5.21,5.21,0,0,0,3.78-1.34A5.83,5.83,0,0,0,79.07,10c0-2-.54-3.48-1.64-4.38a6.23,6.23,0,0,0-4.07-1.34,5.25,5.25,0,0,0-1.55.18l-.66.13V15.62Zm0,14.11a6.36,6.36,0,0,0,4.67-1.58,6,6,0,0,0,1.6-4.48q0-6.08-6.27-6.07H71.15V29.29a2.7,2.7,0,0,0,.73.22,7,7,0,0,0,1.85.22Z"
        />
        <path
          class="cls-1"
          d="M90.8,31a2.87,2.87,0,0,1-1-1.72,16.1,16.1,0,0,1-.29-3.49V2.47H94.3V25.78a8,8,0,0,0,.52,3.32,2.06,2.06,0,0,0,2.06,1.07h.37v1.32H92.83A3.47,3.47,0,0,1,90.8,31Z"
        />
        <path
          class="cls-1"
          d="M106.36,4.45h-2.62a4,4,0,0,0-3.15,1.23A6.06,6.06,0,0,0,99.5,9.73H98.39V6c0-2.34,1-3.52,3.14-3.52h17.32v2h-7.7v27h-4.79Z"
        />
        <path class="cls-1" d="M17.5,0A7.5,7.5,0,0,0,10,7.5V50H0V0Z" />
        <path class="cls-1" d="M35,0V50H17.5A7.5,7.5,0,0,0,25,42.5V0Z" />
        <path
          class="cls-1"
          d="M10,35.5A4.69,4.69,0,0,0,11.8,33c.29-.84.49-1.73.71-2.66a46.39,46.39,0,0,1,1.72-5.87,20.53,20.53,0,0,1,3.47-6,9.81,9.81,0,0,1,3.21-2.51A4.21,4.21,0,0,1,25,16a4.68,4.68,0,0,0-1.79,2.49c-.29.84-.49,1.73-.71,2.65A46.5,46.5,0,0,1,20.77,27a20.69,20.69,0,0,1-3.48,6.05,9.79,9.79,0,0,1-3.21,2.5A4.19,4.19,0,0,1,10,35.5Z"
        />
      </g>
    </g>
  </svg>
);
