import * as React from "react";

import "./dashboard.page.scss";
import { calendarSerivce } from "../../services";
import { classesIfTrue, joinClasses } from "../../utils";

export const DashboardPage = props => (
  <div>
    <Body />
  </div>
);
const Body = props => (
  <div className="container-fluid">
    <div className="pt-4 d-flex">
      <CalendarListBar />
      <CalendarContainer />
    </div>
  </div>
);

const CalendarContainer = props => (
  <div className="flex-grow-1">
    <CalendarDropdown />
    <YearCalendar />
  </div>
);

const CalendarListBar = props => {
  const classes = "btn btn-block";
  const tiles = calendarSerivce.getCalendarList().map((name, i) => (
    <button
      className={joinClasses(
        classes,
        classesIfTrue("btn-primary", i === 0, "btn-outline-primary")
      )}
      key={i}
    >
      {name}
    </button>
  ));
  return (
    <div
      className="flex-shrink-0 d-flex flex-column mr-5"
      style={{ width: "350px" }}
    >
      <h3 className="text-center my-3 text-muted">Your Calendars</h3>
      {tiles}
      <button className={joinClasses(classes, "btn-outline-secondary")}>
        + New
      </button>
    </div>
  );
};

const CalendarDropdown = props => (
  <div>
    <h3 className="font-weight-light my-3">Default Calendar</h3>
  </div>
);

// Year Calendar

const year = [
  [
    "January",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
  ],
  [
    "February",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28
    ]
  ],
  [
    "March",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
  ],
  [
    "April",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30
    ]
  ],
  [
    "May",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
  ],
  [
    "June",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30
    ]
  ],
  [
    "July",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
  ],
  [
    "August",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
  ],
  [
    "September",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30
    ]
  ],
  [
    "October",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
  ],
  [
    "November",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30
    ]
  ],
  [
    "December",
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31
    ]
  ]
];

const YearCalendar = props => {
  return (
    <div className="calendar">
      {year.map((month, monthNo) => (
        <div key={month[0]} className="month my-1">
          <span className="d-inline-block">{month[0]}</span>
          <div className="d-inline-block">
            {month[1].map((day, dayNo) => (
              <DayTile month={monthNo} day={day} key={dayNo} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const DayTile = props => {
  const [marked, setMarked] = React.useState(false);

  return (
    <div
      className="day-number"
      style={getColorBackground(props.month, props.day, marked)}
      onClick={() => setMarked(!marked)}
    >
      {props.day}
    </div>
  );
};

//! Year Calendar

const getColorBackground = (monthNo, dayNo, marked) => {
  console.log("rerender");
  const xColor = 188 - (dayNo / 30) * 122;
  const yColor = (monthNo / 11) * 255;
  // const xyColor = (dayNo / 30 * 60) + (monthNo / 11 * 60);

  let alpha = 1;
  if (!marked) {
    alpha = 0.2;
  }
  return { backgroundColor: `rgba(${yColor}, 30, ${xColor}, ${alpha})` };
};
