export const Routes = {
  Dashboard: "/",
  LogIn: "/log-in",
  SignUp: "/sign-up",
  Profile: "/profile"
};

export const navigateTo = (history, route) => () => {
  history.push(route);
};
