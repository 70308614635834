import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { navigateTo, Routes } from "../../routing";
import { SignComponent } from "../../components/sign-in-up/sign-in-up";

const checkIfIsMobile = () => window.innerWidth < 557;

export const LogInPage = props => (
  <SignComponent
    top={<h2 className="text-center py-3 font-weight-light">Habit Calendar</h2>}
    middle={<LogInForm />}
    bottom={<Bottom />}
  />
);

const LogInForm = withRouter(props => (
  <form onSubmit={navigateTo(props.history, Routes.Dashboard)}>
    <div className="form-group py-3 m-0">
      <input
        className="form-control"
        type="email"
        placeholder="Email Address"
      />
    </div>
    <div className="form-group py-3 m-0">
      <input className="form-control" type="password" placeholder="Password" />
    </div>

    <button className="btn btn-primary btn-block my-3" type="submit">
      Log In
    </button>
  </form>
));

const Bottom = props => {
  return (
    <div>
      <p className="text-center">
        Don't have an account? Create it <Link to={Routes.SignUp}>here</Link>.
      </p>
      <div className="text-center pb-3">
        <Link to={Routes.Dashboard}>
          <img src="/assets/google-button.png" alt="Sign In with Google" />
        </Link>
      </div>
    </div>
  );
};
