import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PageNotFound } from "./components/page-not-found";
import { LogInPage } from "./pages/log-in/log-in.page";
import { DashboardPage } from "./pages/dashboard/dashboard.page";
import { Routes } from "./routing";
import { SignUpPage } from "./pages/sign-up/sign-up.page";
import { ProfilePage } from "./pages/profile/profile.page";
import { Nav } from "./components/nav";

export default class Root extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path={Routes.LogIn} component={LogInPage} />
          <Route path={Routes.SignUp} component={SignUpPage} />
          <Route component={Logged} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    );
  }
}

const Logged = () => (
  <div>
    <Nav />
    <Switch>
      <Route exact path={Routes.Dashboard} component={DashboardPage} />
      <Route path={Routes.Profile} component={ProfilePage} />
    </Switch>
  </div>
);
