import * as React from "react";

export const ProfilePage = () => (
  <div className="w-100">
    <ProfilePicture />
    <ProfileInfoBlock />
  </div>
);

const ProfilePicture = () => (
  <div className="my-3 w-100 d-flex justify-content-center">
    <img
      src="https://i.pravatar.cc/150?img=27"
      alt="Profile Picture"
      className="img-thumbnail rounded-circle"
    />
  </div>
);

const ProfileInfoBlock = () => (
  <div className="container">
    <div className="row">
      <div className="col-md-4 offset-md-4">
        <div className="form-group">
          <label className="font-weight-bold">Name</label>
          <input
            type="text"
            className="form-control"
            value="Adam Smith"
            readOnly={true}
          />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4 offset-md-4">
        <div className="form-group">
          <label className="font-weight-bold">Email</label>
          <input
            type="email"
            className="form-control"
            value="adam.smith@example.com"
            disabled={true}
          />
        </div>
      </div>
    </div>
  </div>
);
