import * as React from "react";
import { Link } from "react-router-dom";
import { SignComponent } from "../../components/sign-in-up/sign-in-up";
import { Routes } from "../../routing";

export const SignUpPage = props => (
  <SignComponent middle={<Form />} bottom={<Bottom />} />
);

const Form = props => (
  <form>
    <div className="form-group py-3 m-0">
      <input
        type="email"
        className="form-control"
        placeholder="Email Address"
      />
    </div>
    <div className="form-group py-3 m-0">
      <input type="password" className="form-control" placeholder="Password" />
    </div>
    <button className="btn btn-block btn-primary my-3" type="submit">
      Create Account
    </button>
  </form>
);

const Bottom = props => (
  <div>
    <p className="text-center">You may also create an account with google</p>
    <div className="text-center pb-3">
      <Link to={Routes.Dashboard}>
        <img src="/assets/google-button.png" alt="Sign In with Google" />
      </Link>
    </div>
    <p className="text-center">
      Already got an account? Click <Link to={Routes.LogIn}>here</Link> to log
      in.
    </p>
  </div>
);
