import * as React from "react";
import PropTypes from "prop-types";
import { classesIfTrue, joinClasses } from "../../utils";
import "./sign-in-up.scss";
import { HabitCalendarLogoLightBg } from "../../svcs";

const checkIfIsMobile = () => window.innerWidth < 557;

export const SignComponent = props => {
  const [isMobile, setIsMobile] = React.useState(checkIfIsMobile);
  React.useEffect(() => {
    const resizer = () => {
      checkIfIsMobile() ? setIsMobile(true) : setIsMobile(false);
    };
    window.addEventListener("resize", resizer, false);
    return () => window.removeEventListener("resize", resizer);
  });
  return (
    <div className="h-100 sign-page-bg">
      <div
        className={joinClasses(
          "container sign-page-spacer",
          classesIfTrue("h-100", isMobile)
        )}
      >
        <div className="row justify-content-center h-100">
          <div className="col-lg-6 col-md-8 h-100">
            <div
              className="card h-100"
              style={{ backgroundColor: "rgba(190,190,190,0.87)" }}
            >
              <div className="card-body">
                <Header />
                {props.middle}
                {props.bottom}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignComponent.props = {
  middle: PropTypes.element,
  bottom: PropTypes.element
};

const Header = props => (
  <div style={{ maxWidth: "50%" }} className="my-4 mx-auto">
    {/*<h2 className="text-center font-weight-light py-3">Habit Calendar</h2>*/}
    <HabitCalendarLogoLightBg />
  </div>
);
