import * as React from "react";
import { withRouter } from "react-router-dom";
import { navigateTo, Routes } from "../routing";
import { HabitCalendarLogoDargBg } from "../svcs";

export const Nav = props => (
  <div className="navbar navbar-light bg-light bg-dark">
    <div className="container-fluid">
      <a
        href={Routes.Dashboard}
        className="navbar-brand text-light d-block"
        style={{ width: 80 }}
      >
        <HabitCalendarLogoDargBg />
      </a>
      <div>
        <Avatar />
      </div>
    </div>
  </div>
);

const Avatar = withRouter(props => {
  const logOut = navigateTo(props.history, Routes.LogIn);
  return (
    <div className="dropdown p-2" style={{ cursor: "pointer" }}>
      <div
        className="avatar dropdown-toggle"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 24C6 19 6 15 15 15C24.2381 15 24 19 24 24" />
          <path d="M11 9C11 12 12 13 15 13C18 13 19 12 19 9C19 6 18 5 15 5C12 5 11 6 11 9Z" />
        </svg>
      </div>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="dropdownMenuButton"
      >
        <a className="dropdown-item" href={Routes.Profile}>
          Profile
        </a>
        <div className="dropdown-divider" />
        <button className="dropdown-item" onClick={logOut}>
          Log Out
        </button>
      </div>
    </div>
  );
});
